import { graphql, useStaticQuery } from "gatsby";

export const useNeedAdviceBoxImages = () => {
  const { person, logo } = useStaticQuery(graphql`
    query NeedAdviceBoxImages {
      person: file(relativePath: { eq: "NeedAdviceBox/prst.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "NeedAdviceBox/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return {
    person: person.childImageSharp.fluid,
    logo: logo.childImageSharp.fluid,
  };
};
