import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { SectionTitle } from "../../Shared/SectionTitle/SectionTitle";
import { NeedAdviceBox } from "./components/LeftSide/NeedAdviceBox";
import { WhyThisJobBox } from "./components/RightSide/WhyThisJobBox";
export interface LetsStartSectionProps {
  className?: string;
}

const LetsStartSectionInner = (props: LetsStartSectionProps) => {
  const { t } = useTranslation("translations");
  return (
    <Section className={props.className}>
      <Container>
        <SectionTitle textAlign="left">{t("niceToMeetYou.title")}</SectionTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <NeedAdviceBox />
          </Grid>
          <Grid item xs={12} md={9}>
            <WhyThisJobBox />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const LetsStartSection = styled(LetsStartSectionInner)`
  max-width: 100vw;
  overflow: hidden;
`;
