import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useMemo } from "react";
import styled from "styled-components";
import { routes } from "../../../../../lib/routes";
import { Button } from "../../../../Shared/Button/Primary/Button";
import { ViewMoreButton } from "../../../../Shared/Button/ViewMore/ViewMoreButton";
import { theme } from "../../../../Shared/Theme/Theme";
import { CustomAccordions } from "./CustomAccordions";

export interface WhyThisJobBoxProps {
  className?: string;
}

const PARAGRAPH_INDEX_TO_BOLD = 0;

const Intro = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.color.sectionBg};

  ${ViewMoreButton} {
    margin-right: 30px;
  }
`;

const BoldHeader = styled.h3`
  margin-bottom: 30px;
`;

const BottomBox = styled(Grid)`
  padding: 30px;
`;

export const ShortText = styled.div`
  color: ${props => props.theme.color.gray};
  margin: 15px auto 0;
  max-width: 350px;
  text-align: center;
`;

const WhyThisJobBoxInner = (props: WhyThisJobBoxProps) => {
  const { t } = useTranslation("translations");
  const paragraphs: string[] = useMemo(
    () => t("niceToMeetYou.main.text", { returnObjects: true }),
    []
  );

  return (
    <div className={props.className}>
      <Intro>
        {paragraphs.map((para: string, index: number) => (
          <>
            {index === PARAGRAPH_INDEX_TO_BOLD ? (
              <BoldHeader key={index}>{para}</BoldHeader>
            ) : (
              <p key={index}>{para}</p>
            )}
          </>
        ))}

        <CustomAccordions />
      </Intro>

      <BottomBox container spacing={4} justify="center">
        <Grid container item sm={5} xs={12} justify="center">
          <a
            href={t("socials.linkedin.url")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="primary">{t("socials.linkedin.title")}</Button>
          </a>
        </Grid>

        <Grid container item sm={5} xs={12} justify="center">
          <Link to={routes.whyWithMe.to}>
            <Button type="primary">{t("buttons.whyWithMe")}</Button>
          </Link>
        </Grid>
      </BottomBox>
    </div>
  );
};

export const WhyThisJobBox = styled(WhyThisJobBoxInner)`
  li {
    color: ${theme.color.gray};
  }
`;
