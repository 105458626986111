import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { routes } from "../../lib/routes";
import { TextSection } from "../Shared/Layout/components/TextSection/TextSection";

export interface SolutionsTextSectionProps {
  className?: string;
}

const SolutionsTextSectionInner = () => {
  const { t } = useTranslation("translations");
  return (
    <TextSection
      title={t("solutionsOrientedBanner.title")}
      description={t("solutionsOrientedBanner.text")}
      button={t("solutionsOrientedBanner.button")}
      buttonUrl={routes.services.to}
    />
  );
};

export const SolutionsTextSection = styled(SolutionsTextSectionInner)``;
