import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { routes } from "../../../../../lib/routes";
import { Button } from "../../../../Shared/Button/Primary/Button";
import { useNeedAdviceBoxImages } from "../../hooks/useNeedAdviceBoxImages";

export interface NeedAdviceBoxProps {
  className?: string;
}

const ProfilePhotoWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  height: 380px;
  overflow: hidden;

  img {
    height: 88% !important;
  }
`;
const LogoWrapper = styled.div`
  margin: 0 auto;
  max-width: 140px;
  width: 100%;
`;
const AdviceTitle = styled.h3`
  margin: 25px 0;
`;

const TelNumber = styled.a`
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.theme.color.yellow};
`;
const OpenningHours = styled.p``;
const NeedAdviceBoxInner = (props: NeedAdviceBoxProps) => {
  const { t } = useTranslation("translations");
  const images = useNeedAdviceBoxImages();
  return (
    <div className={props.className}>
      <ProfilePhotoWrapper>
        <GatsbyImage fluid={images.person} />
      </ProfilePhotoWrapper>
      <AdviceTitle>{t("niceToMeetYou.side.title")}</AdviceTitle>
      <TelNumber href={`tel: ${t("contact.phone")}`}>
        {t("contact.phone")}
      </TelNumber>
      <OpenningHours> {t("contact.available")}</OpenningHours>
      {/* //TODO: function to send messsage */}
      <Link to={routes.contact.to}>
        <Button type="secondary">{t("niceToMeetYou.side.button")}</Button>
      </Link>
      <LogoWrapper>
        <GatsbyImage fluid={images.logo} />
      </LogoWrapper>
    </div>
  );
};

export const NeedAdviceBox = styled(NeedAdviceBoxInner)`
  height: 100%;
  text-align: center;

  ${Button} {
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;
