import React from "react";
import SvgCoaching from "../assets/TheMostRequestedServices/Coaching";
import { HeroSection } from "../components/Home/HeroSection/HeroSection";
import { SolutionsTextSection } from "../components/Home/SolutionsTextSection";
import { LetsStartSection } from "../components/Home/LetsStartSection/LetsStartSection";
import { EventsTextSection } from "../components/Home/EventsTextSection";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";
import { BlogSection } from "../components/Home/BlogSection/BlogSection";
import { FormSection } from "../components/Shared/FormSection/FormSection";

const IndexPage = () => (
  <Layout>
    <SEO description="This is an index page" />
    <HeroSection />
    <SolutionsTextSection />
    <LetsStartSection />
    {/* <EventsTextSection /> */}
    <BlogSection />
    <FormSection />
  </Layout>
);

export default IndexPage;
