import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { CustomAccordion } from "../../../../Shared/CustomAccordion/CustomAccordion";
import { useWhyThisJobBoxSections } from "../../hooks/useWhyThisJobBoxSections";

export interface CustomAccordionsProps {
  className?: string;
}

interface Question {
  question: string;
  answer: string;
}

const CustomAccordionsInner = (props: CustomAccordionsProps) => {
  const { t } = useTranslation("translations");
  const posts = useWhyThisJobBoxSections();
  const questions: Question[] = t("niceToMeetYou.accordion", {
    returnObjects: true
  });

  return (
    <div className={props.className}>
      {posts.map((post, index) => (
        <CustomAccordion
          key={index}
          title={post.title}
          markdownSource={post.content}
        />
      ))}
    </div>
  );
};

export const CustomAccordions = styled(CustomAccordionsInner)`
  margin-top: 40px;
`;
